import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { FormattedMessage, useIntl, navigate } from 'gatsby-plugin-intl'

import media, { sizes } from '../../components/css/media'

import AccountWrapper from './wrapper'
import storageService from '../../services/storageService'

import { KycStatus } from '../../utils/constants'

import icon_verification from '../../images/icon_verification.svg'
import icon_verified from '../../images/icon_verified.svg'
import icon_document_id from '../../images/icon_document_id.svg'
import icon_plus from '../../images/icon_plus.svg'
import icon_document_bill from '../../images/icon_document_bill.svg'
import useModal from '../../hooks/useModal'
import Modal from '../../components/common/modal'

import ChevronLeftIcon from '../../images/icon_chevron_left_white.svg'
import TimesIcon from '../../images/icon_times_white.svg'

const Title = styled.div`
  text-align: center;
  font-weight: 300;
  background-color: #000;
  color: #fff;
  line-height: 50px;
  padding: 0 1rem;

  > img {
    float: left;
    margin: 0.9rem 0 0 0;

    &:last-child {
      float: right;
    }
  }

  ${media.tablet`
    font-size: 1.5em;
    font-weight: 600;
    color: #000;
    line-height: initial;
    padding: 0;
    background-color: #fff;
    text-align: left;

    > img {
      display: none;
    }
  `};
`

const KycStatusWrapper = styled.div`
  text-align: center;
  font-size: 1em;
  color: #ffb300;
  margin-bottom: 3rem;
  font-weight: 600;
  margin: 2rem 0;

  > div {
    color: #000;
    font-weight: 400;
    font-size: 0.8em;
  }

  ${media.tablet`
      font-size: 1.25em;
      margin: 0;
    `};
`

const DocumentUploadWrapper = styled.div`
  width: 60%;
  margin: 1rem auto;

  ${media.tablet`
      display: flex;
      margin: 3rem auto;
    `};
`

const DocumentUploadInfo = styled.div`
  line-height: 1.2;
  margin: 1.5rem 0;

  b {
    font-weight: 600;
  }

  ${media.tablet`
    width: 25%;
    flex: 1 0 25%;
    margin: 0;
  `};
`

const DocumentUploadPlus = styled.div`
  ${media.tablet`
      width: 10%;
      flex: 1 0 10%;
      margin: 2rem 0 0 0;
    `};
`

const Verification = () => {
  const breakpoint = sizes.tablet
  const { closeModal } = useModal()
  const [width, setWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : breakpoint
  )

  const onResizeWindow = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResizeWindow)
    }

    return () => {
      window.removeEventListener('resize', onResizeWindow)
    }
  })

  const handleClose = (action) => {
    closeModal()
    action === 'close' ? navigate('/casino') : navigate('/account')
  }

  return (
    <AccountWrapper>
      {width < breakpoint && (
        <Modal
          theme='account'
          title={''}
          isOpen={width < breakpoint}
          onClose={handleClose}
        >
          <VerificationContent closeModal={handleClose} />
        </Modal>
      )}

      {width > breakpoint && <VerificationContent />}
    </AccountWrapper>
  )
}

const VerificationContent = ({ closeModal }) => {
  const intl = useIntl()
  const user = storageService.getUser()

  return (
    <>
      <Title>
        <img
          src={ChevronLeftIcon}
          alt='Left'
          width={12}
          onClick={() => closeModal('back')}
        />
        <FormattedMessage id='account.verification.verification' />
        <img
          src={TimesIcon}
          alt='Close'
          width={12}
          onClick={() => closeModal('close')}
        />
      </Title>
      <KycStatusWrapper>
        {user?.kyc_status.name === KycStatus.UNVERIFIED && (
          <>
            <img
              src={icon_verification}
              alt='Kyc Icon'
              width={49}
              height={62}
            />
            <p>
              <FormattedMessage id='account.verification.accountNotVerified' />
            </p>

            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: 'account.verification.sendDocumentToEmail',
                }),
              }}
            />
          </>
        )}
        {user?.kyc_status.name === KycStatus.VERIFIED && (
          <>
            <img src={icon_verified} alt='Kyc Icon' width={49} height={62} />
            <p>
              <FormattedMessage id='account.verification.accountVerified' />
            </p>

            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: 'account.verification.sendUpdatedDocumentToEmail',
                }),
              }}
            />
          </>
        )}

        <DocumentUploadWrapper>
          <DocumentUploadInfo>
            <img src={icon_document_id} alt='Document ID' width={100} />
            <p>
              <b>
                1.{' '}
                <FormattedMessage id='account.verification.identificationDocument' />
              </b>
              <br />
              <FormattedMessage id='account.verification.copyOfPhotoId' />
            </p>
          </DocumentUploadInfo>
          <DocumentUploadPlus>
            <img src={icon_plus} alt='Plus' width={37} />
          </DocumentUploadPlus>
          <DocumentUploadInfo>
            <img src={icon_document_bill} alt='Document Bill' width={100} />
            <p>
              <b>
                2. <FormattedMessage id='account.verification.utilityBill' />
              </b>
              <br />
              <FormattedMessage id='account.verification.copyOfUtilityBill' />
            </p>
          </DocumentUploadInfo>
        </DocumentUploadWrapper>
      </KycStatusWrapper>
    </>
  )
}

export default Verification
